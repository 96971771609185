/* NavBar.css */
body {
  margin: 0;
  padding: 0;
}
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-block: 5px;
  background-color: rgba(34, 46, 57, 255);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  height: 70px;
  /* border: 2px solid red; */
}

.logo {
  cursor: pointer;
}

.nav-links ul {
  list-style: none;
  display: flex;
  gap: 40px;
  margin-right: 25px;
  padding: 0;
}

.nav-links ul li a {
  color: white;
  font-size: larger;
  text-decoration: none;
  cursor: pointer;
  transition: color 0.3s;
}

.nav-links ul li a:hover,
.nav-links ul li a.active {
  color: #007bff;
}

.mobile-menu-icon {
  cursor: pointer;
  display: none;
  margin-right: 20px;
}

@media screen and (max-width: 768px) {
  .navbar {
    flex-direction: row; /* Change to row to align items horizontally */
    align-items: center;
    padding-inline-end: 20px;
  }

  .nav-links {
    display: none;
    width: 100%;
    text-align: center;
    background-color: rgba(34, 46, 57, 255);
    position: absolute;
    top: 60px;
    right: 0; /* Position on the right side */
    transform: translateX(100%); /* Initially off-screen */
    transition: transform 0.3s ease-in-out;
    z-index: 1;
  }

  .nav-links.mobile-open {
    display: block;
    transform: translateX(0);
  }

  .mobile-menu-icon {
    display: block;
    z-index: 2; /* Ensure the menu icon is above the menu */
  }
}
