.home-container {
  text-align: center;
  padding: 80px 0px; /* Reduce padding for better mobile readability */
  background-color: #f0f0f0;
  /* border: 2px solid red; */
}

.home-image {
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
}

@media screen and (max-width: 768px) {
  .home-image {
    width: 100%;
    height: 60vh; /* Adjust the height as needed */
  }
}
