.footer {
  background-color: #333;
  color: #fff;
  padding: 20px;
  text-align: center;
}

.footer a {
  color: #fff;
  text-decoration: none;
  margin: 0 10px;
}

.footer a:hover {
  text-decoration: underline;
}

.footer-address {
  margin-top: 20px;
  font-size: 0.9rem;
}
.footerEmail {
  text-align: right;
}
/* Responsive styling */
@media screen and (max-width: 768px) {
  .footer {
    padding: 20px 0;
  }
  .footerEmail {
    text-align: center;
  }
}
