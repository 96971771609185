.partner-container {
  text-align: center;
  padding-block: 80px;
  padding-inline: 20px;
  /* border: 2px solid red; */
}

.partner-images {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}

.partner-images img {
  max-width: 100%;
  height: auto;
}
