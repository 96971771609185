/* Services.css */
.services-container {
  text-align: center;
  padding: 80px 20px; /* Reduce padding for better mobile readability */
  background-color: #f9f9f9;
}

.services-title {
  font-size: 1.5rem; /* Reduce font size for better mobile readability */
  font-weight: bold;
  color: #333;
  margin-bottom: 20px; /* Reduce margin for better mobile spacing */
}

.service-row {
  display: flex;
  flex-direction: column; /* Stack services vertically on smaller screens */
  align-items: center; /* Center align services on smaller screens */
}

.service {
  text-align: center;
  margin: 20px 0; /* Add margin between services on smaller screens */
}

.service img {
  max-width: 100%;
  height: auto;
  margin-bottom: 10px; /* Reduce margin for better mobile spacing */
}

.service h2 {
  margin-top: 10px;
  font-size: 1.2rem; /* Reduce font size for better mobile readability */
  font-weight: bold;
}

.service p {
  margin-top: 10px;
  font-size: 1rem;
  color: #666;
}

@media screen and (min-width: 768px) {
  /* Add styles for larger screens if needed */
  .service-row {
    flex-direction: row; /* Display services in a row on larger screens */
    justify-content: space-around; /* Space services evenly on larger screens */
  }

  .service {
    max-width: 300px; /* Restore max-width for larger screens if desired */
    margin: 0 10px; /* Restore margin for larger screens if desired */
  }
}
