.about-us {
  padding: 80px 20px;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
}

.about-content {
  flex: 1;
  padding-right: 20px;
  max-width: 800px;
  text-align: justify;
}

.about-content h2 {
  font-size: 2rem;
  margin-bottom: 20px;
}

.about-content p {
  margin-bottom: 10px;
}

.about-content strong {
  font-weight: bold;
}

.about-content ul {
  padding-left: 20px;
}

.about-content li {
  margin-bottom: 10px;
}

.aboutImage {
  padding-block: 50px;
  width: 550px;
  height: 600px;
  border-radius: 55px;
}

/* Responsive styling */
@media screen and (max-width: 768px) {
  .about-us {
    flex-direction: column;
    text-align: center;
  }

  .about-content {
    padding: 0;
    max-width: 100%;
  }
  .aboutImage {
    width: 100%;
    height: auto;
    max-width: 600px;
  }
}
