/* MobileMenu.css */
.mobile-menu {
  display: none;
  width: 100%;
  text-align: center;
  background-color: rgba(34, 46, 57, 255);
  position: absolute;
  top: 50px;
  left: 0;
  transition: transform 0.3s ease-in-out;
  z-index: 1;
}

.mobile-menu.open {
  display: block;
  transform: translateX(0);
}

.mobile-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.mobile-menu ul li {
  padding: 15px;
}

.mobile-menu ul li a {
  color: white;
  font-size: larger;
  text-decoration: none;
  cursor: pointer;
  transition: color 0.3s;
}

.mobile-menu ul li a:hover {
  color: #007bff;
}
