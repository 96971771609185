.join-us-container {
  text-align: center;
  padding: 100px 20px; /* Reduce padding for better mobile readability */
  background-color: #f9f9f9;
  will-change: opacity, transform; /* Specify properties to trigger hardware acceleration */
  margin-top: 30px;
}

.join-us-title {
  font-size: 2rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 30px;
}

form {
  max-width: 60%;
  margin: 0 auto;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 6px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.form-group {
  margin-bottom: 20px;
}

label {
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
}

input[type="text"],
input[type="number"],
input[type="tel"],
select {
  width: 50%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
}

input[type="file"] {
  padding: 10px;
}

button[type="submit"] {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

button[type="submit"]:hover {
  background-color: #0056b3;
}

/* Mobile responsiveness */
@media screen and (max-width: 768px) {
  form {
    max-width: 90%;
  }
}
